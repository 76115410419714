import { InvestorsDataType } from './investorsData'
import { Link } from 'routes/components/Shared'
import classes from './InvestorsBlock.module.scss'

export const InvestorsBlockCard = ({ item }: { item: InvestorsDataType }) => {
  const { name, url, logo } = item

  return (
    <li className={classes.item}>
      <Link href={url} className={classes.link}>
        <img src={logo.src} alt={name} className={classes.logo} />
      </Link>
    </li>
  )
}
