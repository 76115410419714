import { StaticImageData } from 'next/image'
import ReviewMatthewBPhoto from './review-photo-matthew-b.png'
import ReviewLucioCPhoto from './review-photo-lucio-c.jpg'
import ReviewJoshJPhoto from './review-photo-josh-j.png'
import ReviewMuharremTPhoto from './review-photo-muharrem-t.png'
import ReviewMukunthKPhoto from './review-photo-mukunth-k.png'

export type ReviewsDataType = {
  name: string
  photo: StaticImageData
  position: string
  company: string
  content: string
  integrationLink: string | undefined
  g2Link: string | undefined
}

export const reviewsData: ReviewsDataType[] = [
  {
    name: 'Mukunth K.',
    photo: ReviewMukunthKPhoto,
    position: 'CTO',
    company: 'Maverick',
    content:
      'The team is excellent and friendly. Communication is via a Slack channel and I get almost all of my queries answered within a few minutes to an hour.',
    integrationLink: undefined,
    g2Link: '/reviews/integration-app-review-9829885',
  },
  {
    name: 'Matthew B.',
    photo: ReviewMatthewBPhoto,
    position: 'Co-Founder',
    company: 'Whalesync',
    content:
      "The speed with which your team is responsive is really impressive. You guys are just on top of it. It's given us a lot of confidence that it's going to be a really good partnership.",
    integrationLink:
      'https://integration.app/articles/case-studies/integrations-for-data-sync-platform-whalesync',
    g2Link: undefined,
  },
  {
    name: 'Josh J.',
    photo: ReviewJoshJPhoto,
    position: 'Lead Engineer',
    company: 'White Label Loyalty',
    content:
      "Everyone from our team keeps highlighting how responsive the Integration.app team is compared to some of the third parties we work with. That's a massive thing to get answer to question in minutes.",
    integrationLink: undefined,
    g2Link: '/reviews/integration-app-review-9008690',
  },
  {
    name: 'Muharrem T.',
    photo: ReviewMuharremTPhoto,
    position: 'CTO',
    company: 'Findymail',
    content:
      'I think one of the best advantages, we directly worked with their developers during implementation they were helpful and they understood our scenarios and real world problems and came back with solutions all the time. Amazing support from day one.',
    integrationLink: undefined,
    g2Link: '/reviews/integration-app-review-8918802',
  },
  {
    name: 'Lucio C.',
    photo: ReviewLucioCPhoto,
    position: 'Lead Engineer',
    company: 'Whalesync',
    content:
      "The level of support and responsiveness from the <span>Integration.app</span> team has been commendable. They've been hands-on in assisting with the building and troubleshooting of connectors, making the whole experience smoother.",
    integrationLink: undefined,
    g2Link: '/reviews/integration-app-review-9527625',
  },
]
