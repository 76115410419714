import { tableHeadData, tableBodyData } from './tableData'
import { ChecklistTableHeadRow } from './ChecklistTableHeadRow'
import { ChecklistTableBodyRow } from './ChecklistTableBodyRow'
import clsx from 'clsx'
import classes from './ChecklistTable.module.scss'

export const ChecklistTable = ({ className }: { className?: string }) => {
  return (
    <table className={clsx(classes.table, className)}>
      <thead className={classes.head}>
        {tableHeadData.map((item, idx) => (
          <ChecklistTableHeadRow key={idx} item={item} />
        ))}
      </thead>
      <tbody className={classes.body}>
        {tableBodyData.map((item, idx) => (
          <ChecklistTableBodyRow key={idx} item={item} />
        ))}
      </tbody>
    </table>
  )
}
