export type TableHeadDataType = {
  rowNumber: number | string
  colTitle1: string
  colTitle2: string
  colTitle3: string
}

export type TableBodyDataType = {
  rowNumber: number
  title: string
  product1: boolean
  product2: boolean
}

export const tableHeadData: TableHeadDataType[] = [
  {
    rowNumber: '',
    colTitle1: 'A',
    colTitle2: 'B',
    colTitle3: 'C',
  },
  {
    rowNumber: 1,
    colTitle1: 'Requirement',
    colTitle2: 'Product 1',
    colTitle3: 'Product 2',
  },
]

export const tableBodyData: TableBodyDataType[] = [
  {
    rowNumber: 2,
    title: 'Management API',
    product1: true,
    product2: false,
  },
  {
    rowNumber: 3,
    title: 'Unified API',
    product1: false,
    product2: true,
  },
  {
    rowNumber: 4,
    title: 'Real-time Data',
    product1: true,
    product2: true,
  },
  {
    rowNumber: 5,
    title: 'Metadata',
    product1: false,
    product2: true,
  },
  {
    rowNumber: 6,
    title: 'Built-in UI',
    product1: true,
    product2: false,
  },
  {
    rowNumber: 7,
    title: 'Custom UI',
    product1: true,
    product2: false,
  },
  {
    rowNumber: 8,
    title: 'Monitoring and Troubleshooting',
    product1: true,
    product2: true,
  },
  {
    rowNumber: 9,
    title: 'Development Process / CI / CD',
    product1: false,
    product2: true,
  },
]
