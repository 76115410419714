import { TbCheck, TbX } from 'react-icons/tb'
import { TableBodyDataType } from './tableData'
import clsx from 'clsx'
import classes from './ChecklistTable.module.scss'

export const ChecklistTableBodyRow = ({
  item,
}: {
  item: TableBodyDataType
}) => {
  const { rowNumber, title, product1, product2 } = item

  const iconType = (type: boolean) => {
    return type ? (
      <TbCheck className={clsx(classes.icon, classes.icon__check)} />
    ) : (
      <TbX className={clsx(classes.icon, classes.icon__x)} />
    )
  }

  return (
    <tr className={clsx(classes.row, classes.row__body)}>
      <th scope='row' className={clsx(classes.cell, classes.cell__number)}>
        {rowNumber}
      </th>
      <td className={clsx(classes.cell, classes.cell__body)}>{title}</td>
      <td className={clsx(classes.cell, classes.cell__body)}>
        {iconType(product1)}
      </td>
      <td className={clsx(classes.cell, classes.cell__body)}>
        {iconType(product2)}
      </td>
    </tr>
  )
}
