import { StaticImageData } from 'next/image'
import CorticalVenturesLogo from './cortical-ventures-logo.svg'
import CrewCapitalLogo from './crew-capital-logo.svg'
import DataRobotLogo from './data-robot-logo.svg'
import SeedCampLogo from './seed-camp-logo.png'
import UiPathLogo from './ui-path-logo.svg'

export type InvestorsDataType = {
  name: string
  url: string
  logo: StaticImageData
}

export const investorsData: InvestorsDataType[] = [
  {
    name: 'Cortical Ventures',
    url: 'https://cortical.vc/',
    logo: CorticalVenturesLogo,
  },
  {
    name: 'Crew Capital',
    url: 'https://crew.vc/',
    logo: CrewCapitalLogo,
  },
  {
    name: 'UiPath',
    url: 'https://www.uipath.com/',
    logo: UiPathLogo,
  },
  {
    name: 'Seedcamp',
    url: 'https://seedcamp.com/',
    logo: SeedCampLogo,
  },
  {
    name: 'DataRobot',
    url: 'https://www.datarobot.com/',
    logo: DataRobotLogo,
  },
]
