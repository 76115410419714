import { TableHeadDataType } from './tableData'
import clsx from 'clsx'
import classes from './ChecklistTable.module.scss'

export const ChecklistTableHeadRow = ({
  item,
}: {
  item: TableHeadDataType
}) => {
  const { rowNumber, colTitle1, colTitle2, colTitle3 } = item

  return (
    <tr className={clsx(classes.row, classes.row__head)}>
      <th scope='col' className={clsx(classes.cell, classes.cell__number)}>
        {rowNumber}
      </th>
      <th scope='col' className={clsx(classes.cell, classes.cell__head)}>
        {colTitle1}
      </th>
      <th scope='col' className={clsx(classes.cell, classes.cell__head)}>
        {colTitle2}
      </th>
      <th scope='col' className={clsx(classes.cell, classes.cell__head)}>
        {colTitle3}
      </th>
    </tr>
  )
}
