import { Carousel, useCarousel } from 'routes/components/Shared/Carousel'
import { ReviewsCard } from './ReviewsCard'
import { reviewsData } from './reviewsData'
import classes from './Reviews.module.scss'

export const Reviews = () => {
  const { slidesToRender, carouselSettings } = useCarousel(reviewsData)

  return (
    <Carousel className={classes.slider} {...carouselSettings}>
      {slidesToRender.map((card, index) => {
        return (
          <div className={classes.slide} key={index}>
            <ReviewsCard card={card} />
          </div>
        )
      })}
    </Carousel>
  )
}
