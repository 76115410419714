import { investorsData } from './investorsData'
import { InvestorsBlockCard } from './InvestorsBlockCard'
import clsx from 'clsx'
import classes from './InvestorsBlock.module.scss'

export const InvestorsBlock = ({ className }: { className: string }) => {
  return (
    <section className={clsx('page__section', classes.section, className)}>
      <h2 className={classes.title}>Backed by experts in SaaS and AI</h2>

      <ul className={classes.list}>
        {investorsData.map((item, index) => (
          <InvestorsBlockCard key={index} item={item} />
        ))}
      </ul>
    </section>
  )
}
